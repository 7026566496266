import Vue from "vue";
import axios from "axios";
import isObject from "lodash/isObject";
import store from "@/store";
import {SET_LOADING} from "@/store/loading/actions";

const apiPrefix = Vue.prototype.$config.homeUrl + '/api_shop_app/';

const webApi = axios.create({
  baseURL: apiPrefix,
  timeout: 30000,
});

webApi.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";

// const token = document.head.querySelector('meta[name="csrf-token"]');
// const token = {
//    content: localStorage.getItem("access_token")
// }

// if (token) {
//   webApi.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
// } else {
//   console.error("CSRF token not found");
// }



webApi.setAcceptLanguage = (lang) => {
  webApi.defaults.headers.common["Accept-Language"] = lang;
};

webApi.interceptors.request.use(
  (config) => {
      store.dispatch(SET_LOADING, true)
    if (!navigator.onLine) return Promise.reject(new Error("You are offline"));

    return config;
  },
  (error) => Promise.reject(error)
);

webApi.interceptors.response.use(
  (response) => {
      store.dispatch(SET_LOADING, false)
      return response
  },
  (error) => {
      store.dispatch(SET_LOADING, false)
    if (error.response) {
      if (error.response.data && isObject(error.response.data)) {
        // todo check session time out and csrf token time out
        if (error.response.data) {
          if (error.response.data.err_code === "TOKEN_NO_ACCESS") {
            location.replace("/login");
          }
        }
        if (error.response.data.message)
          error.message = error.response.data.message;
      } else if (
        error.response.data &&
        typeof error.response.data === "string"
      ) {
        error.message = error.response.data;
      }
    }

    return Promise.reject(error);
  }
);

export default webApi;
